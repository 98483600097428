export const onCreateDrawEvent = `subscription OnCreateDrawEvent {
  onCreateDrawEvent {
    id
    type
    timestamp
    newDraw {
      brandName
      id
      status
      openTime
      closeTime
      drawTime
      resultsAvailableTime
      rows {
        status
        name
        shortName
        description
        eventId
        event {
            id
            name
            sportId
            sportName
            categoryId
            categoryName
            tournamentId
            tournamentName
            teams {
                id
                name
                shortName
            }
            date
            status
        }
        type
        competitors {
            name
            number
            status
            handicap
            odds {
                odds
            }
        }
      }
    }
    oldDraw {
      brandName
      id
      status
      openTime
      closeTime
      drawTime
      resultsAvailableTime
      rows {
        status
        name
        shortName
        description
        eventId
        event {
            id
            name
            sportId
            sportName
            categoryId
            categoryName
            tournamentId
            tournamentName
            teams {
                id
                name
                shortName
            }
            date
            status
        }
        type
        competitors {
            name
            number
            status
            handicap
            odds {
                odds
            }
        }
      }
    }
    competitorIndex
  }
}
`;
