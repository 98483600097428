import React from 'react';
import {GridColumn, GridRow} from "semantic-ui-react";

class DrawEvent extends React.Component {
    constructor(props) {
        super(props);
        this.setUp(props.event);
    }

    setUp(event) {
        this.event = event;
        this.type = this.event.type;
        this.timestamp = this.event.timestamp;
        this.draw = this.event.newDraw;
        this.row = this.draw.rows[0];
        this.sportName = this.row.event.sportName;
        switch (this.type) {
            case "COMPETITOR_ODDS_CHANGED":
                this.competitorOddsChanged(event.oldDraw.rows[0].competitors[event.competitorIndex], event.newDraw.rows[0].competitors[event.competitorIndex]);
                break;
            case "ROW_STATUS_CHANGED":
                this.rowStatusChanged(event.newDraw.rows[0]);
                break;
            case "COMPETITOR_STATUS_CHANGED":
                this.competitorStatusChanged(event.newDraw.rows[0].competitors[event.competitorIndex]);
                break;
            default:
                this.color = "black";
                this.eventDescription = "";
        }
    }

    competitorOddsChanged(oldCompetitor, newCompetitor) {
        const competitorName = newCompetitor.name;
        const oldOdds = oldCompetitor.odds.odds / 100.0;
        const newOdds = newCompetitor.odds.odds / 100.0;
        const direction = oldOdds > newOdds ? "pieneni" : "kasvoi";
        this.eventDescription = `Merkin "${competitorName}" kerroin ${direction}: oli ${oldOdds}, nyt ${newOdds}`;
        this.color = oldOdds > newOdds ? "LightPink" : "LightGreen";
    }

    rowStatusChanged(newRow) {
        const newRowStatus = newRow.status;
        this.color = newRowStatus === "OPEN" ? "White" : "Gray";
        this.eventDescription = newRowStatus === "OPEN" ? "Peli avautui" : "Peli sulkeutui";
    }

    competitorStatusChanged(newCompetitor) {
        const newCompetitorStatus = newCompetitor.status;
        const competitorName = newCompetitor.name;
        this.color = newCompetitorStatus === "ACTIVE" ? "White" : "Gray";
        this.eventDescription = newCompetitorStatus === "ACTIVE" ? `Merkki "${competitorName}" avautui` : `Merkki "${competitorName}" sulkeutui`;
    }

    render() {
        return (
            <GridRow stretched style={{backgroundColor: this.color}}>
                <GridColumn width={3}>
                    {
                        new Date(this.timestamp).toLocaleTimeString("default")
                    }
                </GridColumn>
                <GridColumn width={1}>
                    <a target="_blank" rel="noopener noreferrer"
                       href={"https://www.veikkaus.fi/fi/pitkaveto?eventView=games&eventId=" + this.row.eventId}>
                        {
                            this.draw.brandName
                        }
                    </a>
                </GridColumn>
                <GridColumn width={2}>
                    {
                        this.sportName
                    }
                </GridColumn>
                <GridColumn width={4}>
                    {
                        this.row.event.name
                    }
                </GridColumn>
                <GridColumn width={3}>
                    {
                        this.row.description
                    }
                </GridColumn>
                <GridColumn width={3}>
                    {
                        this.eventDescription
                    }
                </GridColumn>
            </GridRow>
        );
    }
}

export default DrawEvent;
