import React from 'react';
import {Container, Header, Segment} from 'semantic-ui-react';

import Amplify from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';
import DrawEventView from './components/DrawEventView';

import awsconfig from './aws-exports.js';

Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = 'INFO';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        document.title = "AUVO";
    }

    render() {
        return (
            <div>
                <Segment className="inverted center aligned" style={{minHeight: "50px"}}>
                    <Container>
                        <Header as='h1' textAlign='center' style={{marginTop: '2em', color: 'yellow'}}>
                            AUVO
                        </Header>
                    </Container>
                </Segment>
                <Container>
                    <DrawEventView/>
                </Container>
            </div>
        );
    }
}

const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
        {
            label: 'Email',
            key: 'username',
            required: true,
            placeholder: 'Email',
            type: 'email',
            displayOrder: 1,
        },
        {
            label: 'Password',
            key: 'password',
            required: true,
            placeholder: 'Password',
            type: 'password',
            displayOrder: 2,
        },
    ],
};

export default withAuthenticator(App, {signUpConfig});