const awsconfig = {
    Auth: {
        identityPoolId: 'eu-central-1:5d52d401-72b0-409e-b4ce-aa4842ef9010',
        region: 'eu-central-1'
    },
    aws_project_region: "eu-central-1",
    aws_cognito_identity_pool_id: "eu-central-1:5d52d401-72b0-409e-b4ce-aa4842ef9010",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_dDhb2EZom",
    aws_user_pools_web_client_id: "6r32215oj09jm8hc6es87hgb14",
    oauth: {},
    aws_appsync_graphqlEndpoint: "https://4slfp3r64bhb5jwcazypqrfsq4.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-central-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_content_delivery_bucket: "auvo-ui-hostingbucket-prod",
    aws_content_delivery_bucket_region: "eu-central-1",
    aws_content_delivery_url: "http://auvo-ui-hostingbucket-prod.s3-website.eu-central-1.amazonaws.com"
};

export default awsconfig;
