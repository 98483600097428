import React, {Component} from 'react';
import {graphqlOperation} from 'aws-amplify';
import {Connect} from 'aws-amplify-react';
import DrawEvent from './DrawEvent';
import * as subscriptions from '../graphql/subscriptions'
import {Grid, Menu} from 'semantic-ui-react';

class DrawEventView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawEvents: []
        };
        this.events = new Map();
    }

    clearDrawEvents = () => {
        this.events.clear();
        this.setState({});
    };

    handleDrawEvent(drawEvent) {
        console.log(drawEvent);
        if (drawEvent.type !== "DRAW_CLOSE_TIME_CHANGED" && drawEvent.type !== "NEW_DRAW") {
            if (drawEvent.type === "DRAW_REMOVED") {
                this.events.delete(drawEvent.id);
            } else {
                this.events.set(drawEvent.id, drawEvent);
            }
            this.setState({});
        }
    }

    render() {
        return <div>
            <Menu>
                <Menu.Item name='clear' active={true} onClick={this.clearDrawEvents}>Tyhjenn&auml;</Menu.Item>
            </Menu>
            <Connect
                subscription={graphqlOperation(subscriptions.onCreateDrawEvent)}
                onSubscriptionMsg={(prev, {onCreateDrawEvent}) => {
                    this.handleDrawEvent(onCreateDrawEvent);
                    return prev;
                }}
            >
                {() => {
                }}
            </Connect>
            <Grid>
                {
                    [...this.events.values()]
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .map(event => {
                            return <DrawEvent key={event.id} event={event}/>
                        })
                }
            </Grid>
        </div>
    }
}

export default DrawEventView;
